<template>
    <v-card class="pa-3" max-width="800">
        <h1>{{ $t("resetPassword.title") }}</h1>
        <v-subheader>{{ $t("resetPassword.subheader") }}</v-subheader>
            <validation-observer
                ref="observer"
            >
                <v-form @submit.prevent="submit">
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required|email"
                        name="email"
                    >
                        <div>
                            <v-text-field
                                v-model="form.email"
                                :label="$t('resetPassword.label_email')"
                                :error-messages="errors"
                                required
                                type="email" 
                                name="email" 
                                id="email"
                            ></v-text-field>
                        </div>
                    </validation-provider>
                    
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:8"
                        name="password"
                    >
                        <div>
                            <v-text-field
                                v-model="form.password"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                :label="$t('resetPassword.label_password')"
                                required
                                counter
                                :error-messages="errors"
                                name="password" 
                                id="password"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                            <password v-model="form.password" :strength-meter-only="true" secureLength=8 />
                        </div>
                        <div class="pt-5">
                            <v-btn :color="button.color" :dark="button.dark" type="submit" :name="button.text" value="submit">{{ button.text }}</v-btn>
                        </div>
                        <div class="mt-5" v-if="success">{{$t('resetPassword.success')}}</div>
                    </validation-provider>
                </v-form>
            </validation-observer>
        </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate';
import { required, email, min} from 'vee-validate/dist/rules'
import Password from 'vue-password-strength-meter'
import { ILLI_AUTH_SERVER_URL } from '../config'
export default Vue.extend({
    name: "resetPassword",
    components: {
        ValidationProvider,
        ValidationObserver,
        Password
    },

    data () {
        return ({
            success:false,
            showPassword: false,
            form: {
                email: '',
                password: ''
            },
            button: {
                text: this.$t("resetPassword.label_submit"),
                color: "",
                dark: false
            }
        })
    },
    beforeMount () {
        setInteractionMode('eager')
        extend('required', required)
        extend('email', email)
        extend('min', min)
        configure({
            defaultMessage: (field, values) => {
                values._field_ = this.$t(`resetPassword.label_${field}`);
                return this.$t(`validations.messages.${values._rule_}`, values);
            }
        });
    
        axios
        .post(`${ILLI_AUTH_SERVER_URL}/resetPasswordValidID`, { key: this.$route.params.key})
        .catch(response => {
            console.log(response)
            if(!response.data.valid) {
                this.$router.replace({
                    name: 'gekoppeldeApparaten'
                })
            }
        })
    },
    methods: {
        submit() {
            axios
            .post(`${ILLI_AUTH_SERVER_URL}/resetPassword`, {...this.form, key: this.$route.params.key})
            .then(response => {
                if(response.status === 202) {
                    this.button = {
                        text: this.$t("resetPassword.saved"),
                        color: "green",
                        dark: true
                    }
                    this.success = true;
                    setTimeout(()=>{
                        this.$router.replace({
                            name: 'login'
                        })
                    }, 5000)
                }
            })
        }
    }
})
</script>